libsResolve();

async function startApp(initial_params) {
    if (!initial_params) {
        initial_params = {
            language: window.localStorage.getItem('language'),
            session: window.localStorage.getItem('session'),
            tempid: window.localStorage.getItem('tempid'),
        }
    }

    await whenModules('initialized');

    let data = { };
    runMethod('onBeforeGetAll', data);

    core.libs.ws.sendJson({
        action: '*/getAll',
        initial_params: initial_params,
        data
    });

    resetPromises('ready');
    resetPromises('readyForActions');

    await whenModules('ready');

    runMethod('onReady');

    await whenModules('readyForActions');

    deviceReadyPromise.then(() => {
        runMethod('onDeviceReady');
        runActionUrl(current_action ? current_action : (app_config.default_action_url ? app_config.default_action_url : 'home'));
        if (location.hash) {
            window.onhashchange();
        }
    });
}

if (!window.localStorage.getItem('tempid')) {
    window.localStorage.setItem('tempid', uniqid());
}

$('body').attr('url', '');
$('body').attr('action', '');

reset = function() {
    window.localStorage.clear();
    window.location.reload(true);
}

var hash_changed_index = 0;
window.onhashchange = function() {
    var action = location.hash.substr(1, 999);
    triggerUrl(action);

//    if (action) {
//        var params_ = action.split('/');
//        var v;
//        var message = { action: params_[0] };
//
//        $('body').attr('hash', action);
//        $('body').attr('action', params_[0]+(params_[1] ? '/'+params_[1] : ''));
//
//        if (params_.length > 1) {
//            message.action += '/' + params_[1];
//        }
//
//        for(key in params_) {
//            if (key > 1) {
//                if (key % 2) {
//                    message[v] = params_[key];
//                } else {
//                    v = params_[key];
//                }
//            }
//        }
//
//        runAction(message, true);
//    }

    hash_changed_index++;
};