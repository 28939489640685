/*
 * Layout.
 */

class Layout {
    constructor() {
    }

    async onReady() {
        await this.render();
    }

    async render() {
        var date = new Date();

        $('#main').remove();
        $(document.body).append(core.template.render(core.template.layout.main, { }));
        if (window.localStorage.getItem('return_to_page') != '') {
            //app.page.addloadingOnPage('main');
        }

        window.addEventListener('resize', () => {
            app.layout.updateVh();
        });


        $('body').attr('lang', core.lang.language);
        await runMethod('onLayoutReady');
    }

    async onChangeLanguage(language) {
        $('body').attr('lang', language);
        core.lang.language = language;
        await this.render();
        
        if(app.user.groups.logged) {
            core.libs.ws.sendAndWait({
                action: 'user/changeLanguage',
                language
            });
        }
    }

    updateVh() {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    async onPermissionsChanged() {
        if (core.template.layout) {
            $('#main').html(core.template.render(core.template.layout.main, {}));
            $('#user-email').html(app.user.email);
        }
    }

    async onTriggerUrl(data) {
        app.breadcrumbItems = [];
        $('#breadcrumb').empty().hide();
    }

    async onTriggerUrlAfter(data) {
        $('#menu a').removeClass('active');
        $(`#menu a[href="${data.action}"]`).addClass('active');
        $(`#menu a[href="/${data.action}"]`).addClass('active');

        for(let item of app.breadcrumbItems) {
            $(`#menu a[href="${item.url}"]`).addClass('active');
            $(`#menu a[href="/${item.url}"]`).addClass('active');
        }
    }
}

app.layout = new Layout();

