/*
 * Mustache templates.
 */

class Template {
    // List of partials, all templates are available this way: {{> layout.header }}
    // Dynamic partials: {{> $partial_name_in_variable }} where partial_name_in_variable = 'layout.header';
    partials = [];
    
    constructor() {
        addPromise(this, 'ready');
    }

    async setAction(request) {
        if (request.data) {
            for(var section in request.data) {
                this[section] = request.data[section];
                for(var key in request.data[section]) {
                    if (typeof request.data[section][key] === 'string') {
                        this.partials[section+'.'+key] = request.data[section][key];
                    } else {
                        for(var key2 in request.data[section][key]) {
                            this.partials[section+'.'+key+'.'+key2] = request.data[section][key][key2];
                        }
                    }
                }
            }
            
            resolvePromise(this, 'ready');
        }
    }
    
    // User logged in.
    async onPermissionsChanged() {
        await core.libs.ws.sendJson({
            action: 'template/getAll',
        });
    }
    
    render(template, data) {
        if (!data) {
            data = {};
        }
        if (typeof mustache_functions !== 'undefined') {
            data = Object.assign(data, mustache_functions);
        } 
        if (!data.hasOwnProperty('app')) {
            data.app = app;
        }
        if (!data.hasOwnProperty('core')) {
            data.core = core;
        }
        if (!data.hasOwnProperty('lang')) {
            data.lang = core.lang;
        }
        /*if (!data.hasOwnProperty('date')) {
            data.date = () => {
                console.log(data, 'data')
            };
        }*/
        
        for(var prop in data) {
            data[prop] = orm.base.detect(data[prop]);
        }
        
        return Mustache.render(template, data, this.partials);
    }
}

core.template = new Template();

