// ******************************************************************************************************************** Modules

function getProperty(property, list) {
    var properties = [];
    for (var module in list) {
        if (list[module].hasOwnProperty(property)) {
            properties.push(list[module][property]);
        }
    }

    return properties;
}

function getNotResolved(prefix, property, list) {
    var properties = [];
    for (var module in list) {
        if (list[module].hasOwnProperty(property) && !list[module][property].isFulfilled) {
            properties.push(prefix + module + '.' + property);
        }
    }

    return properties;
}

async function whenModules(property, alert_time = 1000) {
    const status_app = getProperty(property, app);
    const status_core = getProperty(property, core);
    const status_libs = getProperty(property, core.libs);
    var status = status_app.concat(status_core).concat(status_libs);
    if (app_config.debug_promisses) {
        console.log('whenModules', property, status);
    }

    var al = setTimeout(function () {
        var list;
        console.log('whenModules(' + property + ') timeout');
        list = getNotResolved('app.', property, app)
        if (list.length) {
            console.log(list);
        }

        list = getNotResolved('core.', property, core);
        if (list.length) {
            console.log(list);
        }

        list = getNotResolved('core.libs.', property, core.libs);
        if (list.length) {
            console.log(list);
        }

    }, alert_time);

    await Promise.all(status).then(results => {
        clearTimeout(al);
        return results;
    }).catch(err => {
        console.log('whenModules(' + property + ') rejected');
        console.log(err);
    });
}

function checkLogged() {
    if(!app.user.hasGroup('logged')) {
        runAction({ action: 'user/login' });
        throw 'logged group is required!';
    }
}

async function runMethod(method) {
    for (var module in app) {
        if (typeof app[module][method] === 'function') {
            await app[module][method].apply(app[module], Array.prototype.slice.call(arguments, 1));
        }
    }
    for (var module in core) {
        if (typeof core[module][method] === 'function') {
            await core[module][method].apply(core[module], Array.prototype.slice.call(arguments, 1));
        }
    }
}

function resetPromises(name) {
    for (var module in app) {
        if (app[module].hasOwnProperty(name)) {
            addPromise(app[module], name);
        }
    }

    for (var module in core) {
        if (core[module].hasOwnProperty(name)) {
            addPromise(core[module], name);
        }
    }
}

function runActionHash(hash) {
    location.hash = '#' + hash;
}

function runActionUrl(url, data = {}) {
    try {
        if ($(url).length && $(url).attr('href')) {
            url = $(url).attr('href');
        }
    } catch (e) { }
    if (url[0] == '/') {
        url = url.substring(1, 9999);
    }

    if (!mobile_app) {
        history.pushState(data, url, url);
    }
    window.scrollTo(0, 0);
    triggerUrl(url, data);
    return false;
}

onpopstate = function(event) {
    triggerUrl(window.location.pathname.substr(1, 999));
}

var session_refresh = 0;
sessionRefresh = function() {
    session_refresh = setTimeout(async function() {
        await core.libs.ws.sendAndWait({
            action: 'functions/ping',
        });
    }, 30000);
}

triggerUrl = async function(action, data = { }) {
    data.action = action;
    if (data.action === undefined) {
        data.action = current_action;
    }

    await runMethod('onTriggerUrl', data);

    if (data.action) {
        sessionRefresh();

        current_action = data.action;
        var params_ = data.action.split('/');
        var v;
        var message = Object.assign({ }, data);
        message.action = params_[0];

        $('body').attr('url', data.action);
        $('body').attr('action', params_[0]+(params_[1] ? '/'+params_[1] : ''));

        if (params_.length > 1) {
            message.action += '/' + params_[1];
        }

        for(key in params_) {
            if (key > 1) {
                if (key % 2) {
                    message[v] = params_[key];
                } else {
                    v = params_[key];
                }
            }
        }

        await runAction(message, true);
        await runMethod('onTriggerUrlAfter', data);
    }
}

getCurrentAction = function() {
    return current_action;
}

async function runAction(message_, return_response) {
    var message = typeof message == 'object' ? Object.assign({}, message_) : message_;
    if (message == undefined) return;

    /*$('body').attr('hash', message.action);
    $('body').attr('action', message.action);*/

    if(typeof message == 'string') {
        if (message[0] == '/') {
            message = message.substring(1, 9999);
        }
        let params_ = message.split('/');
        let v;
        var message = {};
        message.action = params_[0];
        if (params_.length > 1) {
            message.action += '/' + params_[1];
        }
        for(key in params_) {
            if (key > 1) {
                if (key % 2) {
                    message[v] = params_[key];
                } else {
                    v = params_[key];
                }
            }
        }
    }

    if (message.action == 'index.html') {
        message.action = 'home';
    }

    var params_ = message.action.split('/');

    var found = false;

    var self = this;
    if (params_.length == 1) {
        params_ = ['page', params_[0]];
    }

    if (params_.length >= 2) {
        var module = params_[0];
        var action = params_[1] + 'Action';

        for(key in params_) {
            if (key > 1) {
                if (key % 2) {
                    message[v] = params_[key];
                } else {
                    v = params_[key];
                }
            }
        }

        if (app[module] !== undefined && typeof app[module][action] === 'function') {
            if (!return_response) {
                found = true;
                app[module][action](message)
                        .then((response) => {
                            if (response) {
                                self.send(response);
                            }
                        })
                        .catch(err => {
                            console.log(err);
                        });
            } else {
                return app[module][action](message);
            }
        }

        if (core[module] !== undefined && typeof core[module][action] === 'function') {
            if (!return_response) {
                found = true;
                core[module][action](message)
                        .then((response) => {
                            if (response) {
                                self.send(response);
                            }
                        });
            } else {
                return core[module][action](message);
            }
        }
    }

    if (!found) {
        if (mobile_app) {
            await runActionHash('home');
        } else {
            console.log('Action not found', message);
            await runMethod('onTriggerUrlNotFound', message);
        }
    }
}

var current_action = window.location.pathname.substr(1, 999);

function addPromise(module, name) {
    if (app_config.debug_promisses) {
        console.log('addPromise', name, module);
    }
    module[name] = new Promise(function (resolve, reject) {
        module[name + '_resolve'] = resolve;
        module[name + '_reject'] = reject;
    });
}

function resolvePromise(module, name) {
    if (app_config.debug_promisses) {
        console.log('resolvePromise', name, module);
    }
    if (module[name + '_resolve'] && typeof module[name + '_resolve'] === 'function') {
        module[name + '_resolve'].apply(module, Array.prototype.slice.call(arguments, 2));
    }
}

// Return 8 chars long uniqid
function uniqid() {
    return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1) +
            Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
}

// ******************************************************************************************************************** Modal/popup

function getSuccessHtml(message) {
    var html = core.lang.get('common.modal_success_html', { message }, true);
    return html !== false ? html : `<div class='text-center'><div class="success-animation"><svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg></div><br />${message}</div>`;
}

function getFailHtml(message) {
    var html = core.lang.get('common.modal_fail_html', { message }, true);
    return html !== false ? html : `<div class='text-center'><img src='public/img/error.gif' alt='Fail' /><br />${message}</div>`;
}

function getConfirmHtml(message) {
    var html = `<div class='text-center'>${message}</div>`;
    return html;
}

core.modal = {
    instances: {},
    zIndex: 1100
};

function showModal(title, body, buttons, hide_existing = true, modal_options = {}) {
    modal_options = modal_options || {};
    var options = {};
    if (hide_existing) {
        /*
        var modal = bootstrap.Modal.getInstance($('.modal'));
        if (modal) {
            $('.modal-backdrop').remove();
            //modal.hide();
        }
        */
        for(let mId in core.modal.instances) {
            core.modal.instances[mId].close();
        }
    }
    //var predef_modal = document.getElementById('predef_modal');
    var predef_modal = $(`
        <div class="modal" tabindex="-1">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title">${title ? title : ''}</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <p>Modal body text goes here.</p>
                    </div>
                    <div class="modal-footer" style="display: none"></div>
                </div>
            </div>
        </div>
    `).get(0);
    var modal_dialog = predef_modal.querySelector('.modal-dialog');

    predef_modal.classList.remove('close');

    if(modal_options.width) {
        if(modal_options.width < window.innerWidth) {
            modal_dialog.style.maxWidth = modal_options.width + 'px';
        } else {
            modal_dialog.style.maxWidth = null;
        }
    }else {
        modal_dialog.style.maxWidth = null;
    }

    for(let cn of predef_modal.classList) {
        if(cn.substr(0, 10) == 'animation-') {
            predef_modal.classList.remove(cn);
        }
//        predef_modal.classList.remove('fade');
    }

    if(modal_options.animation) {
        predef_modal.classList.add('animation-'+modal_options.animation);
        let hide_animation = true;
        $(predef_modal).on('hide.bs.modal', function (event) {
            if(!hide_animation) {
                return ;
            }
            hide_animation = false;
            predef_modal.classList.add('close');
            /*
            modal._queueCallback(() => {
                modal.hide();
            }, modal_dialog, true);
            */
            setTimeout(() => {
                 modal.hide();
            }, 300);
            return false;
        });
    }else {
        //predef_modal.classList.add('fade');
    }

    var modal = new bootstrap.Modal(predef_modal, options);

    modal.setTitle = function (title) {
        $(this._element).find('.modal-title').html(title);
        if (title) {
            $(this._element).find('.modal-title').show();
        } else {
            $(this._element).find('.modal-title').hide();
        }
        return this;
    }

    modal.setBody = function (body) {
        $(this._element).find('.modal-body').html(body);
        return this;
    }

    modal.content = function () {
        return $(this._element).find('.modal-body');
    }

    modal.setBodyWait = function (body) {
        $(this._element).find('.modal-body').html(core.lang.get('common.please_wait'));
        return this;
    }

    modal.setButtons = function (buttons) {
        if (buttons === undefined) {
            this.setButtonClose();
        } else {

            var $footer = $(this._element).find('.modal-footer');
            $footer.html('');
            for (var i in buttons) {
                var dismiss = buttons[i].close ? 'data-bs-dismiss="modal"' : '';
                var $button = $(`<button type="button" class="btn ${buttons[i].class} button-${i}" ${dismiss}>${buttons[i].label}</button>`);
                if (buttons[i].onclick) {
                    $button.on('click', buttons[i].onclick);
                }

                $footer.append($button);
            }

            if (buttons) {
                $footer.show();
            } else {
                $footer.hide();
            }
        }

        return this;
    }

    modal.setButtonClose = function () {
        var buttons = [{label: core.lang.get('common.close'), class: 'btn-primary', close: true}];
        this.setButtons(buttons);
        return this;
    }

    modal.setButtonNone = function () {
        var buttons = [];
        this.setButtons(buttons);
        return this;
    }

    modal.close = () => {
        modal.hide();
    }

    modal.closeAfter = function (time) {
        if (time) {
            var self = this;
            setTimeout(function () {
                self.hide();
            }, time);
        }

        return this;
    }

    modal.hideWithCallback = function (callback) {
//        this.onhide(callback);
//        this.hide();
    }

    modal.onhide = function(callback) {
//        var func = (event) => {
//            this._element.removeEventListener('hidden.bs.modal', func);
//            if (callback) {
//                callback(event);
//            }}
//
//        this._element.addEventListener('hidden.bs.modal', func);
    }
    modal.onclose = function(callback) {
        this._element.addEventListener('hidden.bs.modal', callback);
    }

    modal.onshow = function(callback) {
//        console.log('onshow', callback);
//        var func = (event) => {
//            this._element.removeEventListener('shown.bs.modal', func);
//            if (callback) {
//                console.log('onshow callback');
//                callback(event);
//                this._element.removeEventListener('shown.bs.modal', func);
//                modal.onshow(false);
//            }}
//
//        this._element.addEventListener('shown.bs.modal', func);
    }

    modal.setButtons(buttons);
    //modal.setTitle(title);

    if (body) {
        modal.setBody(body).show();
    }

    modal.zIndex = core.modal.zIndex;
    core.modal.zIndex += 2;
    core.modal.instances[modal.zIndex] = modal;

    if (modal._backdrop._element) {
        modal._backdrop._element.style.zIndex = modal.zIndex;
    }
    modal._element.style.zIndex = modal.zIndex+1;

    $(predef_modal).on('hide.bs.modal', function (event) {
        delete core.modal.instances[modal.zIndex];
        $(predef_modal).remove();
    });

    return modal;
}

function hideModal() {
    var modal = bootstrap.Modal.getInstance($('.modal'));
    if (modal) {
        //modal.onshow(function () { modal.hide(); });
        if(modal._isShown) {
            modal.hide();
        }else {
            modal._resetAdjustments();
            modal._scrollBar.reset();
        }
    }
}

function showModalConfirm(body, callback_on_yes) {
    var modal = showModal(
        core.lang.get('common.confirm'),
        getConfirmHtml(body),
        [
            {label: core.lang.get('common.cancel'), class: 'btn-secondary', close: true},
            {label: core.lang.get('common.yes'), class: 'btn-primary', onclick: callback_on_yes},
        ]
    );

    return modal;
}

function showModalWait() {
    var modal = showModal(
        core.lang.get('common.please_wait'),
        core.lang.get('common.please_wait'),
        [

        ]
    );

    return modal;
}

function showModalSuccess(body, close_after = 3000) {
    var modal = showModal(core.lang.get('common.success'), getSuccessHtml(body));
    modal.closeAfter(close_after);
}

function showModalFail(body, close_after = 3000) {
    var modal = showModal(core.lang.get('common.alert_fail'), getFailHtml(body));
    modal.closeAfter(close_after);
}

// Don't close other modals
function alertSuccess(body, close_after = 3000) {
    var modal = showModal(core.lang.get('common.alert_fail'), getSuccessHtml(body) || body, undefined, false);
    modal.closeAfter(close_after);
}

// Don't close other modals
function alertFail(body, close_after = 3000) {
    var modal = showModal(core.lang.get('common.alert_fail'), getFailHtml(body) || body, undefined, false);
    modal.closeAfter(close_after);
}

function runActionHashConfirmed(hash_action) {
    var modal = showModal(core.lang.get('common.confirm'), core.lang.get('common.confirm_delete'), [
        {label: core.lang.get('common.no'), class: 'btn-secondary', close: true},
        {label: core.lang.get('common.yes'), class: 'btn-primary', close: true, onclick: function () {
                runActionHash(hash_action);
            }},
    ]);
}
