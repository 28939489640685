class Ws {
    // List of actions to call on backend.
    actions = [];

    // Websocket connection.
    ws = false;

    i = 0;

    constructor() {
        addPromise(this, 'initialized');
        this.connect();
    }

    connect() {
        this.i++;

        var self = this;
        let prot = app_config.ssl ? 'wss' : 'ws';

        if (mobile_app) {
            //alert(prot+'://' + app_config.host + ':' + app_config.port);
        }
        //alert(prot+'://' + app_config.host + ':' + app_config.port);
        if (this.i == 3) {
            //showModalFail('Websocket closed!');
            //alert('Connection issues - please check your internet connection ('+prot+'://' + app_config.host + ':' + app_config.port+')');
            //$('body').append('<div id="coninfo" style="width: calc(100% - 20px); top: 40px; left: 10px; text-align: center; line-height: 20px; padding: 10px 0; background: #FFF; border-radius: 10px; z-index: 9999999; position: fixed; color: black;">Connection issues<br />'+prot+'://' + app_config.host + ':' + app_config.port+'</div>');
            $('body').append('<div id="coninfo" style="width: calc(100% - 20px); top: 40px; left: 10px; text-align: center; line-height: 20px; padding: 10px 0; background: #FFF; border-radius: 10px; z-index: 9999999; position: fixed; color: black;">Connection issues<br /></div>');
        }

        console.log('Start connection', new Date());
        this.ws = new WebSocket(prot+'://' + app_config.host + ':' + app_config.port);

        this.ws.addEventListener('open', event => {
            console.log('Connected', new Date());
            this.i = 1;
            $('#coninfo').remove();
            resolvePromise(this, 'initialized');
            startApp();
        });

        this.ws.addEventListener('close', event => {
            //alert('close');
            //console.log('Websocket closed');
            //app.page.reloadAction();
            //return;
            //showModalFail('Websocket closed!');

            setTimeout(function() {
                //console.log('Reconnect '+self.i);
                //addPromise(self, 'initialized');
                //self.connect();
                app.page.reloadAction();
            }, 500);
        });

        this.ws.addEventListener('message', event => {
            this.handleMessage(event.data)
        });
    }

    sendJson(data) {
        if (app_config.debug_ws) {
            console.log('Trace (not error):');
            console.log(new Error().stack);
            console.log('Send to backend', data);
        }

        runMethod('onSendJson', data);
        this.ws.send(JSON.stringify(data));
    }

    add(action, data) {
        data.action = action;
        this.actions.push(data);
        return this;
    }

    sendAll(mode) {
        if (this.actions.length) {
            this.ws.send({actions: this.actions, mode: mode || 'async_wait'});
            this.actions = [];
        }
    }

//    send(action) {
//        if (core.config.debug.ws) {
//            console.log('Send to backend', ws);
//        }
//        this.ws.send(JSON.stringify(action));
//    }

    /**
     * Send message to the backend and wait for the response:
     var ret = await core.libs.ws.sendAndWait({
     action: 'user/canRegister'
     });
     * @param object action
     * @returns Promise
     */
    async sendAndWait(action) {
        var promiseid = 'sendAndWait_' + uniqid();
        if (action.action) {
            promiseid += '_' + action.action.replace('/', '_');
        }
        addPromise(this, promiseid);
        action.promiseid = promiseid;
        clearTimeout(session_refresh);

        runMethod('onBeforeSendAndWait', action);
        this.sendJson(action);
        return this[promiseid];
    }

    handleMessage(_message) {
        var self = this;
        var message;

        if (message = JSON.parse(_message)) {
            runMethod('onReceiveFromBackend', message, _message.length);

            if (app_config.debug_ws) {
                console.log('Received from backend', message);
            }

            // If message was send by: await sendAndWait()
            if (message.promiseid) {
                var promiseid = message.promiseid;
                delete message.promiseid;
                resolvePromise(this, promiseid, message);
                runMethod('onAfterSendAndWait', message);
                return;
            }

            if (message.no_access && !message.action) {
                if (message.action) {
                    showModalFail(message.message);
                }
            }

            /*
             Call module action. Example of calling on frontend:
             var message = { action: 'lang/getAll', data: { }};
             ws.send(JSON.stringify(message));
             */
            if (message.action) {
                runAction(message);
            }

            /*
             Call module action with URL changing. Example of calling on frontend:
             var message = { action_url: 'lang/getAll', data: { }};
             ws.send(JSON.stringify(message));
             */
            if (message.action_url) {
                runActionUrl(message.action_url, message);
            }

            /*
             Call many module(s) actions. Example of calling on frontend:
             var message = {
             actions: [
             { action: 'lang/getAll', data: { } },
             { action: 'config/set', data: { } }
             ],
             mode: 'async'
             };
             ws.send(JSON.stringify(message));

             Modes:
             - sync - call all actions one by one in order on message.actions and return all resposnses when last action is done
             - async - call all actions async and each action will send response
             - async_wait - call all actions async and return all resposnses as one when all is done
             */
            if (message.actions) {
                (async () => {
                    switch (message.mode || 'async_wait') {
                        case 'sync':
                            var responses = [];
                            for (var action_message of message.actions) {
                                await responses.push(runAction(action_message, true));
                            }
                            self.sendJson(responses);
                            break
                        case 'async':
                            for (var action_message of message.actions) {
                                runAction(action_message, false);
                            }
                            break
                        case 'async_wait':
                            var actions = [];
                            for (let action_message of message.actions) {
                                actions.push(runAction(action_message, true));
                            }

                            var responses = await Promise.all(actions);
                            self.sendJson(responses);
                            break
                    }
                })();
            }
        } else {
            if (app_config.debug_ws) {
                console.log('Received message from backend cannot be parsed', _message);
            }
        }
    }
}

core.libs.ws = new Ws();