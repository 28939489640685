/*
 * Panel
 */

class Panel {

    constructor() {
        addPromise(this, 'readyForActions');

    }

    onReady() {
        resolvePromise(this, 'readyForActions')
    }

    async setAction(request) {
       this.data = request.data;
    }

    async productsAction(request) {
        $('#content').html(core.template.render(core.template.panel.products, { }));
    }
}

app.panel = new Panel();