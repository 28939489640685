function getPager(data, btn_class = 'btn-outline-secondary', btn_class_selected = 'btn-secondary') { // data.page, data.count, data.per_page
    data.per_page = data.per_page || core.config.per_page;
    if (data.page === undefined || data.count === undefined || data.per_page === undefined) {
        console.log('Pager render error');
        console.log(data.page, data.count, data.per_page);
        console.trace();
        return;
    }

    var display = '';
    var html = ``;
    if (data.count > data.per_page) {
        var pages = data.count / data.per_page;

        if (data.page > 3) {
            html += `<input type="radio" class="btn-check" name="page" id="btnradio1" value='0' autocomplete="off" checked>
                    <label class="btn ${btn_class}" for="btnradio1">${core.lang.get('common.first')}</label>`;
        }

        var list = 3;
        var start = data.page - list;
        if (start < 0) {
            start = 0;
        }

        for (let i = start; i < pages && i < start + 2*list + 1; i++) {
            let select = i != data.page ? '' : 'checked';
            html += `<input type="radio" class="btn-check" name="page" id="btnradio_${i}" value='${i}' autocomplete="off" ${select}>
                    <label class="btn ${btn_class}" for="btnradio_${i}">${i + 1}</label>`;
        }

        if (pages - data.page - 1 > list) {
            html += `<input type="radio" class="btn-check" name="page" id="btnradio0" value='${pages - 1}' autocomplete="off">
                    <label class="btn ${btn_class}" for="btnradio0">${core.lang.get('common.last')}</label>`;
        }
    } else {
        display = 'style="display: none;"';
        html = `<input type="radio" name="page" value='0' autocomplete="off" checked>`;
    }

    return `<form class="pager-form" ${display}><div class="pager btn-group btn-group-sm" role="group" aria-label="Pager">` + html + `</div></form>`;
}

function onChangePager(data, content_id = 'content') {
    $(`#${content_id} form.pager-form input:radio`).change(function () {
        data.page = core.libs.form.getFormData($(`#${content_id} form.pager-form`)[0]).page;
        
        $form_filter = $(`#${content_id} form.filter-form`);
        if($form_filter.length > 0) {
            data.filter = core.libs.form.getFormData($form_filter[0]);
        }
        
        runAction(data);
    });
}

function onChangeFilter(data, content_id = 'content') {
    $(`#${content_id} form.filter-form input, #${content_id} form.filter-form select`).change(function () {
        data.page = 0;
        data.filter = core.libs.form.getFormData($(`#${content_id} form.filter-form`)[0]);
        
        runAction(data);
    });
}

getFileIcon = function(file, params) {
    let $icon = $(`<div data-id='${file.id}' class='icon'><div class='thumbnail'></div></div>`);

    if (file.thumbnail) {
        file.thumb_url = file.thumbnail
    }

    if (file.thumb_url) {
        $icon.find('.thumbnail').css('background-image', 'url('+file.thumb_url+')');
    }

//    if (params.ondelete) {
//        params.delete_icon = params.delete_icon || `<i class="fas fa-times"></i>`;
//        let $button = $(`<button class='btn btn-primary btn-sm remove'>${params.delete_icon}</i></button>`).click((e) => {
//            if (params.ondelete) {
//                params.ondelete(e, file);
//            }
//
//            e.stopPropagation();
//            return false;
//        });
//
//        $icon.append($button);
//    }

    if (params.icon_callback && !params.icon) {
        params.icon = `<i class="fas fa-times"></i>`;
    }

    if (params.icon) {
        if (params.icon == true) {
            params.icon = `<i class="fas fa-times"></i>`;
        }

        let $button = $(`<button class='btn btn-primary btn-sm click'>${params.icon}</i></button>`).click((e) => {
            if (params.icon_callback) {
                params.icon_callback(e, file);
            }

            e.stopPropagation();
            return false;
        });
        $icon.append($button);
    }

    $icon.click((e) => {
        if (params.onclick) {
            params.onclick(e, file);
        }

        e.stopPropagation();
        return false;
    });

    return $icon;
}

function getUrlParams(action = 'group/show') {

    var result = {};
    var pathname = window.location.pathname;

    if(!pathname.includes(action)) return false;

    var url_params = pathname.split(action)[1];

    var params = url_params.split('/').filter((el) => {
        return el != '';
    });

    if(!params.length) return result;

    params.forEach((x, i, a) => {
        if(i % 2) {
            result[a[i - 1]] = x;
        }
    });

    return result;
}

function breadcrumb(items) {
    items.map(x => x.active = false);
    items.findLast(x => true).active = true;
    $('#breadcrumb').html(core.template.render(core.template.layout.breadcrumb, {items})).show();
    app.breadcrumbItems = items;
}

function breadcrumbAppend(append_items) {
    var items = app.breadcrumbItems.concat(append_items);
    items.map(x => x.active = false);
    items.findLast(x => true).active = true;
    $('#breadcrumb').html(core.template.render(core.template.layout.breadcrumb, {items})).show();
}

function daysInMonth (year, month) {
    return (new Date(parseInt(year), parseInt(month) + 1, 0)).getDate();
}
function firstDayInMonth(year, month) {
    var nr = (new Date(parseInt(year), parseInt(month), 1)).getDay();
    if(nr == 0) {
        nr = 7;
    }
    return nr;
}
function weekCount(year, month) {
    var used = firstDayInMonth(year, month) + daysInMonth(year, month) - 1;

    return Math.ceil( used / 7);
}