/*
 * Displays content page like About us, Contact etc.
 */

class Page {
    timer_interval = false;

    constructor() {
        addPromise(this, 'readyForActions');

        window.addEventListener('resize', () => {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty('--vh', `${vh}px`);
        });

        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    async reloadAction() {
        window.location = '/';
        window.location.reload(true);
    }

    onReady() {
        resolvePromise(this, 'readyForActions')
    }

    async setAction(request) {
       this.data = request.data;
    }

    async homeAction(request) {
        if (!app.user.groups || !app.user.groups.logged) {
            runActionUrl('user/login');
        }
    }
}

app.page = new Page();