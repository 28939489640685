/*
 * Config.
 * 
 * Passed from backend through the websocket.
 */

class Config {
    constructor() {
        addPromise(this, 'ready');
    }

    async setAllAction(request) {
        if (request.data) {
            for (var section in request.data) {
                this[section] = request.data[section];
            }
            
            resolvePromise(this, 'ready');
        }
    }
}

core.config = new Config();
