
class Payment {
    constructor() {

    }
    
    async listAction(data) {
        if(!app.user.hasGroup('service_provider')) return ;
        
        var url_sufix = data.service_provider ? '/service_provider/'+data.service_provider : '';
        var ret = await core.libs.ws.sendAndWait(data);
        ret.pager = getPager(ret);
        ret.url_sufix = url_sufix;
        ret.labels = orm.payment.getLabels();
        
        var $content, template;
        
        if(data.service_provider || !app.user.groups.admin) {
            $content = $('#service-provider-content');
            template = core.template.payment.list;
            breadcrumbAppend([
                {runAction: '/payment/list'+url_sufix, text: core.lang.user.transactions}
            ]);            
        }else {
            $content = $('#content');
            template = core.template.payment.list_wrapper;
        }
        
        $content.html(core.template.render(template, ret));
        
        $content.find('form.pager-form input:radio').change(function () {
            data.page = core.libs.form.getFormData($content.find('form.pager-form')[0]).page;
            runAction(data);
        });
        
        onChangePager(data, $content.attr('id'));
        onChangeFilter(data, $content.attr('id'));
    }
}

app.payment = new Payment();