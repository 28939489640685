class ormPayment extends orm.base {
    
    fields(showEmpty = false) {
        var list = [];
        list.push({
            label: core.lang.get('payment.status'),
            value: core.lang.get('payment.staus_'+(this.status ? 'success' : 'error'))
        });
        list.push({
            label: core.lang.get('payment.date'),
            value: Mustache.Formatters.datetime(this.date)
        });
        list.push({
            label: core.lang.get('payment.amount'),
            value: Mustache.Formatters.price(this.amount)
        });
        list.push({
            label: core.lang.get('payment.tax_amount'),
            value: Mustache.Formatters.price(this.tax_amount)
        });
        for(let field of ['concept', 'description', 'card_type', 'card_firstname', 'card_lastname', 'transaction_id', 'message_sys', 'operation_type']) {
            if(showEmpty || this[field]) {
                list.push({
                    label: core.lang.get('payment.' + field),
                    value: this[field]
                });
            }
        }
        return list;
    }
    
    allFields() {
        return this.fields(true);
    }
    
    static getLabels() {
        var e = new ormPayment();
        return e.allFields().map(x => ({label: x.label}));
    }
}

orm.payment = ormPayment;
