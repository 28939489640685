/*
 * User session
 */

class User {
    session = '';
    groups = {};

    constructor() {
        addPromise(this, 'ready');
        addPromise(this, 'readyForActions');
        this.groups = { };
    }

    // Check config.reset_user_data in DB!
    // Reset all user data on localstorage: app.user.reset();
    reset(time) {
        this.save(time);
        console.log('User data cleared');
    }

    async resetAction(request) {
        //this.reset();
        //window.location.reload();
    }

    async setAction(request) {
        resolvePromise(this, 'ready')

        if (request.data.reset_user_data != window.localStorage.getItem('set_time') && request.data.reset_user_data != -1) {
            this.reset(request.data.reset_user_data);
        }
    }

    onLayoutReady() {
        resolvePromise(this, 'readyForActions')
    }

    async loginAction() {
        // if(app.user.groups.logged) {
        //     runActionUrl('home');
        //     return ;
        // }

        var self = this;
        var ret = await core.libs.ws.sendAndWait({
            action: 'user/login'
        });

        $('#content').html(core.template.render(core.template.user.login, {form: ret.form}));
        $('#login_container a').on('click', function() {
            runActionUrl($(this).attr('href'));
        });

        core.libs.form.handle($('#'+ret.form.id), ret, function (data) {
            (async() => {
                var ret = await core.libs.ws.sendAndWait({
                    action: 'user/login',
                    data: data
                });

                if (ret.status == 'not_valid') {
                    let error = ret.errors.email ? ret.errors.email : ret.errors.password;
                    let $err = $(`<div class='login_error'>${error}</div>`);
                    $('.password-input-box').find('.login_error').remove();
                    $('.password-input-box').append($err);
                } else
                if (ret.errors) {
                    core.libs.form.showErrors(self, ret);
                }

                if (ret.error) {
                    let $err = $(`<div class='login_error'>${ret.message}</div>`);
                    $('.password-input-box').find('.login_error').remove();
                    $('.password-input-box').append($err);
                }

                if (ret.success) {
                    // Refresh user data.
                    await core.libs.ws.sendJson({
                        action: 'user/getAll',
                    });
                }
            })();
        });
    }
    
    async forgotPasswordAction(data) {
        var ret = await core.libs.ws.sendAndWait(data);

        $('#content').html(core.libs.form.render(core.template.user.forgot_password, ret));
        
        var form = await core.libs.form.handle($('#'+ret.form.id), ret, async formData => {
            data.data = formData;
            let ret = await core.libs.ws.sendAndWait(data);

            if (ret.error) {
                showModalFail(ret.message);
                return ;
            }
        
            if (ret.errors) {
                runAction('/user/login');
                form.showErrors(ret);
            }

            if (ret.success) {
                runAction('/user/login');
                showModalSuccess(ret.message, 10000);
            }
        });
    }
    
    async resetPasswordAction(data) {
        var ret = await core.libs.ws.sendAndWait(data);

        if (ret.error) {
            runAction('/user/login');
            showModalFail(ret.message);
            return ;
        }
        
        $('#content').html(core.libs.form.render(core.template.user.reset_password, ret));        
        
        var form = await core.libs.form.handle($('#'+ret.form.id), ret, async formData => {
            data.data = formData;
            let ret = await core.libs.ws.sendAndWait(data);

            if (ret.errors) {
                form.showErrors(ret);
            }

            if (ret.success) {
                runAction('/user/login');
                showModalSuccess(ret.message);
            }
        });
    }

    async onPermissionsChanged() {
        if(app.user.groups.admin) {
            runActionUrl('user/service_providers');
        }else if(app.user.groups.service_provider) {
            runActionUrl('user/service_provider');
        }else {
            runActionUrl('user/login');
        }
    }

    async activateAction(data) {
        if (app.user.hasGroup('admin')) {
            let modal = showModalConfirm(core.lang.get('user.confirm_activate'), async function () {
                modal.hide();
                var ret = await core.libs.ws.sendAndWait(data);
                if (ret.success) {
                    runActionUrl('/user/service_providers');
                    showModalSuccess(core.lang.get('user.account_has_been_activated'));
                }
            });
        }
    }

    async deactivateAction(data) {
        if (app.user.hasGroup('admin')) {
            let modal = showModalConfirm(core.lang.get('user.confirm_deactivate'), async function () {
                modal.hide();
                var ret = await core.libs.ws.sendAndWait(data);
                if (ret.success) {
                    runActionUrl('/user/service_providers');
                    showModalSuccess(core.lang.get('user.account_has_been_deactivated'));
                }
            });
        }
    }

    async setAction(request) {
        var prevous_state = JSON.stringify({
            id: this.id,
            groups: this.groups
        });

        if (request.data) {
            request.data.groups = request.data.groups || {};
            this.id = request.data.id;
            this.name = request.data.name;
            this.email = request.data.email;
            this.session = request.data.session;
            this.groups = request.data.groups;
            
            if(request.data.language) {
                if(core.lang.language != request.data.language) {
                    core.lang.changeLanguage(request.data.language);
                }else {
                    localStorage.setItem('language', request.data.language);                    
                }
            }
        }

        ['id','session'].forEach(key => {
            if (this.hasOwnProperty(key) && this[key]) {
                window.localStorage.setItem(key, this[key]);
            } else {
                window.localStorage.removeItem(key);
            }
        });

        document.cookie = `session=${this.session}; expires=Thu, 18 Dec 2050 12:00:00 UTC; path=/`;

        var current_state = JSON.stringify({
            id: this.id,
            groups: this.groups
        });

        if (prevous_state != current_state) {
            runMethod('onPermissionsChanged');
        }

        resolvePromise(this, 'ready')
    }

    hasGroup(group) {
        if (!this.groups.hasOwnProperty(group)) {
            runActionUrl('user/permission');
            return false;
        }

        return true;
    }

    async permissionAction(request) {
        $('#content').html(core.template.render(core.template.user.permission));
    }

    async registerAction() {
        var ret = await core.libs.ws.sendAndWait({
            action: 'user/register'
        });

        $('#content').html(core.libs.form.render(core.template.user.register, ret));

        var form = core.libs.form.handle($('#'+ret.form.id), ret, async data => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'user/register',
                data: data
            });

            if (ret.errors) {
                form.showErrors(ret);
            }

            if (ret.error) {
                showModalFail(ret.message);
            }

            if (ret.success) {
                $('#content').html(core.template.render(core.template.user.register_success, { }));
            }
        });
    }

    // Edit user profile
    async accountAction(request) {
        if(!app.user.hasGroup('service_provider')) return ;
        
        var ret = await core.libs.ws.sendAndWait(request);
        var url_sufix = request.service_provider ? '/service_provider/'+request.service_provider : '';

        if (ret.error) {
            showModalFail(ret.message, 5000);
            return;
        }
        
        ret.show_message = request.show_message || false;

        $('#service-provider-content').html(core.libs.form.render(core.template.user.account, ret));

        var form = core.libs.form.handle($('#'+ret.form.id), ret, function (data) {
            (async() => {
                request.data = data;
                var ret = await core.libs.ws.sendAndWait(request);

                if (ret.errors) {
                    form.showErrors(ret);
                }

                if (ret.error) {
                    showModalFail(ret.message);
                }

                if (ret.success) {
                    console.log(ret);
                    let user = new ormUser(ret.user);
                    console.log(user);
                    $('#content .avatar').html(user.userHeader());
                    showModalSuccess(ret.message);
                    
                    if(request.show_message == 'fill_account_before_add_product') {
                        runAction('/product/edit'+url_sufix);
                    }
                }
            })();
        });
    }

    async logoutAction() {
        var ret = await core.libs.ws.sendAndWait({
            action: 'user/logout',
        });

        if (ret.success) {
            window.localStorage.clear();
            await this.setAction({ data: ret.data });

            window.localStorage.setItem('logout_notification', ret.message);
            document.cookie = `session=; expires=Thu, 18 Dec 2050 12:00:00 UTC; path=/`;
            window.location = '/';
        }
    }

    async service_providersAction() {
        if(!app.user.hasGroup('admin')) return ;
        
        var ret = await core.libs.ws.sendAndWait({
            action: 'user/service_providers'
        });

        $('#content').html(core.template.render(core.template.user.service_providers, ret));
    }

    async service_providerAction(data = {}) {
        if(!app.user.hasGroup('service_provider')) return ;
        
        var ret = await core.libs.ws.sendAndWait(data);
        ret.link_sufix = app.user.groups.admin ? `/service_provider/${data.id}` : '';

        $('#content').html(core.template.render(core.template.user.service_provider, ret));

        let selfBreadcrumb = () => {
            breadcrumb([
                {url: '/user/service_providers', text: core.lang.user.service_providers},
                {url: '/user/service_provider/id/'+data.id, text: ret.user.name}
            ]);
        };
        selfBreadcrumb();

        $('#service-provider-menu a').click(function() {
            selfBreadcrumb();
            $('#service-provider-menu a').removeClass('active');
            $(this).addClass('active');
            runAction($(this).attr('href'));
            return false;
        }).first().trigger('click');

        $('#service-provider-content').on('click', 'a', function() {
            runAction($(this).attr('href')+ret.link_sufix);
            return false;
        });
    }
    
    async deleteAction(data) {
        var ret = await core.libs.ws.sendAndWait(data);
        
        if (ret.error || ret.render_error) {
            showModalFail(ret.message, 5000);
            runAction('/');
            return ;
        }
                
        $('#content').html(core.template.render(core.template.user.delete_success, ret));
    }

}

app.user = new User();