
class Booking {
    constructor() {

    }

    async listAction(data) {
        var self = this;
        if(!app.user.hasGroup('service_provider')) return ;
        
        var ret = await core.libs.ws.sendAndWait(data);
        
        ret.pager = getPager(ret);
        var $content, template;
        
        if(data.service_provider || !app.user.groups.admin) {
            $content = $('#service-provider-content');
            template = core.template.booking.list;
            breadcrumbAppend([]);
        }else {
            $content = $('#content');
            template = core.template.booking.list_wrapper;
        }

        ret.show_calendar_btn = data.service_provider || !app.user.groups.admin;
        
        $content.html(core.template.render(template, ret));

        onChangePager(data, $content.attr('id'));
        onChangeFilter(data, $content.attr('id'));
        
        if($content.attr('id') == 'content') {            
            $content.on('click', 'a', function() {
                runAction($(this).attr('href'));
                return false;
            });            
        }
        
        $content.find('.btn-booking-sp-calendar').on('click', function(e) {
            var $c = $(this).parent().find('.booking-sp-calendar-container');
            self.calendar($c, null, null, data.service_provider);
        });
    }
    
    async viewAction(data) {
        if(!app.user.hasGroup('service_provider')) return ;
        
        var url_sufix = data.service_provider ? '/service_provider/'+data.service_provider : '';
        var ret = await core.libs.ws.sendAndWait(data);
        ret.url_sufix = url_sufix;
        var $content, template;
        
        if(data.service_provider || !app.user.groups.admin) {
            $content = $('#service-provider-content');
            template = core.template.booking.view;
            breadcrumbAppend([
                {runAction: '/booking/list'+url_sufix, text: core.lang.user.bookings},
                {text: ret.booking.product.name }
            ]);
        }else {
            $content = $('#content');
            template = core.template.booking.view_wrapper;
        }
        
        $content.html(core.template.render(template, ret));
        
    }
    
    async rejectAction(data) {
        if(!app.user.hasGroup('service_provider')) return ;
        
        $('#service-provider-content').find(`.btn-accept-${data.id}, .btn-reject-${data.id}`).attr('disabled', true);
        
        var rejectCallback = async () => {
            data.reason = modal.content().find('textarea').val();
            if(!data.reason) {
                alertFail(core.lang.get('booking.reject_reason_is_required'));
                return ;
            }
            modal.setBodyWait().setButtons();
            var ret = await core.libs.ws.sendAndWait(data);            
            if (ret.error || ret.render_error) {
                alertFail(ret.message, 5000);
            }else {
                $('#service-provider-content').find(`.btn-accept-${data.id}, .btn-reject-${data.id}`).remove();
                $('#service-provider-content').find(`.action-message-${data.id}`).html(core.lang.get('booking.rejected'));
            }
            modal.close();
        };
        
        var html = `
            <div>
                ${core.lang.get('booking.reject_confirm_message')}
            </div>
            <div class="mt-3">
                <textarea class="form-control" placeholder="${core.lang.get('booking.reject_reason')}"></textarea>
            </div>
        `;
        var modal = showModal(
            core.lang.get('booking.reject'),
            html,
            [
                {label: core.lang.get('common.cancel'), class: 'btn-secondary', close: true},
                {label: core.lang.get('booking.reject'), class: 'btn-primary', onclick: rejectCallback}
            ]
        );
        modal.onclose(() => {
            $('#service-provider-content').find(`.btn-accept-${data.id}, .btn-reject-${data.id}`).attr('disabled', false);
        });
    }
    
    async acceptAction(data) {
        if(!app.user.hasGroup('service_provider')) return ;
        
        $('#service-provider-content').find(`.btn-accept-${data.id}, .btn-reject-${data.id}`).attr('disabled', true);
        var ret = await core.libs.ws.sendAndWait(data);            
        if (ret.error || ret.render_error) {
            alertFail(ret.message, 5000);
            $('#service-provider-content').find(`.btn-accept-${data.id}, .btn-reject-${data.id}`).attr('disabled', false);
        }else {
            $('#service-provider-content').find(`.btn-accept-${data.id}, .btn-reject-${data.id}`).remove();
            $('#service-provider-content').find(`.action-message-${data.id}`).html(core.lang.get('booking.accepted'));
        }
    }

    async ratingsAction(data) {
        if(!app.user.hasGroup('service_provider')) return ;
        
        var url_sufix = data.service_provider ? '/service_provider/'+data.service_provider : '';
        var ret = await core.libs.ws.sendAndWait(data);
        ret.pager = getPager(ret);
        ret.url_sufix = url_sufix;
        $('#service-provider-content').html(core.template.render(core.template.booking.ratings, ret));

        breadcrumbAppend([
            {runAction: '/booking/ratings'+url_sufix, text: core.lang.user.ratings}
        ]);

        onChangePager(data, 'service-provider-content');
    }

    async calendar($container, year = null, month = null, service_provider = null) {
        var now = new Date();
        if(!year) {
            year = now.getFullYear();
        }
        if(!month) {
            month = now.getMonth()+1;
        }
        var data = {
            year, month, 
            month_label: core.lang.get('common.months.'+month)
        };
        var is_current_month = year == now.getFullYear() && month == (now.getMonth() + 1);
        data.week_days = Object.entries(core.lang.get('common.week_days')).map(([key, value]) => ({
            "number": key,
            "label": value,
            "short": value.substr(0, 1)
        }));
        data.days_in_month = daysInMonth(year, month-1);
        data.start_day = firstDayInMonth(year, month-1);
        data.week_count = weekCount(year, month-1);
        data.month_data = [];
        var {dates} = await core.libs.ws.sendAndWait({
            action: 'booking/getAcceptedDays',
            date_from: `${year}-${(month<10?'0':'')+month}-01`,
            date_to: `${year}-${(month<10?'0':'')+month}-${data.days_in_month}`, 
            service_provider
        });
        
        let d = 0;
        for(let i = 1; i <= data.week_count; i++) {
            let week = [];
            for(let j = 1; j <= 7; j++) {
                if(d == 0 && i == 1 && j >= data.start_day) {
                    d = 1;
                }
                let html = '';
                if(d > 0) {
                    let date = `${year}-${(month<10?'0':'')+month}-${(d<10?'0':'')+d}`;
                    html = `<div class="day ${(is_current_month && d == now.getDate() ? 'current-day' : '')} ${dates.indexOf(date) != -1 ? 'day-booked' : ''}">
                            ${d}
                            ${dates.indexOf(date) != -1 ? '<div class="day-dot"></div>' : ''}
                        </div>`;
                }
                week.push({
                    "html": html
                });
                if(d > 0) {
                    d++;
                    if(d > data.days_in_month) {
                        d = 0;
                    }
                }
            }
            data.month_data.push({week});
        }

        $container.html(core.template.render(core.template.booking.calendar, data));

        $container.find('.btn-prev').on('click', e => {
            let prev_month = month - 1;
            if(prev_month < 1) {
                year--;
                prev_month = 12;
            }
            this.calendar($container, year, prev_month, service_provider);
        });
        $container.find('.btn-next').on('click', e => {
            let next_month = month + 1;
            if(next_month > 12) {
                year++;
                next_month = 1;
            }
            this.calendar($container, year, next_month, service_provider);
        });

        $(document).off('click.booking_calendar_close').on('click.booking_calendar_close', e => {
            if($(e.target).closest('.booking-calendar').length == 0) {
                $(document).off('click.booking_calendar_close');
                $container.empty();
            }
        });
    }
}


app.booking = new Booking();