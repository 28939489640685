/*
 * This is beginning of Js for frontend, before any module is loaded.
 */

// All core controllers
const core = { libs: { } };

// All app modules
const app = { };
const orm = { };

var libsResolve;
const libsPromise = new Promise((resolve, reject) => {
    libsResolve = resolve;
});

if (mobile_app == undefined) {
    var mobile_app = false;
}

var deviceReadyResolve;
const deviceReadyPromise = new Promise((resolve, reject) => {
    deviceReadyResolve = resolve;
});

if (mobile_app) {
    document.addEventListener("deviceready", onDeviceReady, false);
    function onDeviceReady() {
        deviceReadyResolve();
    }
} else {
    deviceReadyResolve();
}



// See startApp() function