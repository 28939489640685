
libsPromise.then(() => {
    Mustache.Formatters = {
        date: function(date) {
            if(!date) {
                return '';
            }
            if(typeof date != 'object') {
                if(/^\d+$/.test(date)) {
                    date = new Date( parseInt(date) * 1000);
                }else if(/^\d{4}\-\d{2}\-\d{2}/.test(date)) {
                    let tmp = new Date();
                    tmp.setFullYear(parseInt(date.substr(0, 4)));
                    tmp.setMonth(parseInt(date.substr(5, 2))-1);
                    tmp.setDate(parseInt(date.substr(8, 2)));
                    date = tmp;
                }else {
                    date = new Date( date);                    
                }
            }
            return date.toLocaleDateString();
        },
        time: function(date) {
            if(!date) {
                return '';
            }
            if(typeof date != 'object') {
                if(/^\d+$/.test(date)) {
                    date = new Date( parseInt(date) * 1000);
                }else {
                    date = new Date( date);                    
                }
            }
            return date.toLocaleTimeString();
        },
        datetime: function(date) {
            if(!date) {
                return '';
            }
            if(typeof date != 'object') {
                if(/^\d+$/.test(date)) {
                    date = new Date( parseInt(date) * 1000);
                }else {
                    date = new Date( date);                    
                }
            }
            return Mustache.Formatters.date(date) + ' ' + Mustache.Formatters.time(date);
        },
        sqldate: function(date) {
            if(typeof date == 'string') {
                date = new Date(date);
            }
            if(!date) {
                return date;
            }
            var y = date.getFullYear();
            var m = date.getMonth() + 1;
            var d = date.getDate();
            if(m < 10) {
                    m = '0' + m;
            }
            if(d < 10) {
                    d = '0' + d;
            }
            return y + '-' + m + '-' + d;
        },
        bool: function(value) {
            console.log(value, 'value')
            return !!value;
        },
        nl2br: function(value) {
            return value.replaceAll("\n", "<br/>");
        },
        typeof: function(value) {
            return typeof value;
        }
    };
});