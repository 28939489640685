
class Product {
    constructor() {

    }

    async listAction(data) {
        if(!app.user.hasGroup('service_provider')) return ;
        
        var url_sufix = data.service_provider ? '/service_provider/'+data.service_provider : '';
        var ret = await core.libs.ws.sendAndWait(data);
        ret.pager = getPager(ret);
        ret.url_sufix = url_sufix;
        $('#service-provider-content').html(core.template.render(core.template.product.list, ret));

        breadcrumbAppend([
            {runAction: '/product/list'+url_sufix, text: core.lang.user.offers}
        ]);

        onChangePager(data, 'service-provider-content');
    }

    async editAction(data) {
        if(!app.user.hasGroup('service_provider')) return ;
        
        var ret = await core.libs.ws.sendAndWait(data);
        var url_sufix = data.service_provider ? '/service_provider/'+data.service_provider : '';

        if (ret.service_provider_not_valid) {
            runAction('/user/account'+url_sufix+'/show_message/fill_account_before_add_product');
            return ;
        }
        if (ret.error || ret.render_error) {
            showModalFail(ret.message, 5000);
            runAction('/product/list'+url_sufix);
            return ;
        }

        $('#service-provider-content').html(core.libs.form.render(core.template.product.edit, ret));

        breadcrumbAppend([
            {runAction: '/product/list'+url_sufix, text: core.lang.user.offers},
            {text: ret.values.id ? ret.values.name : core.lang.product.new_offer }
        ]);

        var form = await core.libs.form.handle($('#'+ret.form.id), ret, async data => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'product/edit',
                data: data
            });

            if (ret.errors) {
                form.showErrors(ret);
            }

            if (ret.success) {

                runAction('/product/list'+url_sufix);
            }
        });
    }
    
    async deleteAction(data) {
        if(!app.user.hasGroup('service_provider')) return ;
        
        var url_sufix = data.service_provider ? '/service_provider/'+data.service_provider : '';
        var modal = showModalConfirm(core.lang.get('common.delete_confirm_message'), async () => {
            modal.setBodyWait();
            var ret = await core.libs.ws.sendAndWait(data);
            
            if (ret.error || ret.render_error) {
                showModalFail(ret.message, 5000);
            }
            runAction('/product/list'+url_sufix);
            modal.close();
        });
    }
}


app.product = new Product();