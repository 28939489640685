class ormUser extends orm.base {
    userHeader() {
        return `<img src="${this.avatar_url}" class="rounded-circle img-avatar me-2"> ${this.name}`;
    }

    avatar() {
        return `<div class='avatar'><img src='${this.avatar_url}' alt='' /></div>`;
    }
}

orm.user = ormUser;
