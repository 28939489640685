
class ormBase {

    constructor(data = {}) {
        for(var field in data) {
            this[field] = orm.base.detect(data[field]);
        }
    }

    static loadObject(obj) {
        if(orm.hasOwnProperty(obj['_class'])) {
            return new orm[obj['_class']](obj);                    
        }else {
            return new orm.base(obj);
        }
    }
    
    static load(list) {
        return list.map(x => orm.base.loadObject(x));
    }
    
    static detect(data) {
        //console.log('data', data);
        if(typeof data === 'object' && data && data.constructor.name === 'Object' && data.hasOwnProperty('_class')) {
            return orm.base.loadObject(data);
        }else if(typeof data == 'object' && data && data.constructor.name === 'Array' && data.length > 0) {
            if(typeof data[0] === 'object' && data[0] && data[0].constructor.name === 'Object' && data[0].hasOwnProperty('_class')) {
                
                return orm.base.load(data);
            }else {
                return data;
            }
        }else {
            return data;
        }
    }
    
    toString() {
        return this._class + ' #' + this.id;
    }
}

orm.base = ormBase;

