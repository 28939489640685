/*
 * Admin
 */

class Admin {
    constructor() {

    }

    async user_listAction(request) {
        $('#content').html(core.template.render(core.template.admin.user_list, { }));
    }

}

app.admin = new Admin();

