/*
Validator.registerAsync('email_available', function (email, attribute, req, passes) {
    (async() => {
        var ret = await core.libs.ws.sendAndWait({
            action: 'user/emailAvailable',
            email
        });

        passes(ret.available, ret.error);
    })();
});
*/