

libsPromise.then(() => {
    Mustache.Formatters.price = function(price) {
        price = parseFloat(price);
        if(isNaN(price)) return '';
        return '$' + price.toFixed(2);
    }

    // Use: {{ 100 | 4.3 | currency_calc }}
    Mustache.Formatters.currency_calc = function(value, rate) {
        let ret = (Math.round(value / rate * 100) / 100) + "";
        let tmp = ret.split('.');
        if (tmp.length == 1) {
            ret += '.00';
        } else
        if (tmp[1].length == 1) {
            ret += '0';
        }
        return ret;
    }
});