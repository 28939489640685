
class Tag {
    constructor() {

    }
    
    async listAction(data) {
        if(!app.user.hasGroup('admin')) return ;
        
        var ret = await core.libs.ws.sendAndWait(data);
        ret.pager = getPager(ret);
        $('#content').html(core.template.render(core.template.tag.list, ret));
        onChangePager(data);
    }
    
    async editAction(data) {
        if(!app.user.hasGroup('admin')) return ;
        
        var ret = await core.libs.ws.sendAndWait(data);
        
        if (ret.error || ret.render_error) {
            showModalFail(ret.message, 5000);
            runAction('/tag/list');
            return ;
        }

        $('#content').html(core.libs.form.render(core.template.tag.edit, ret));

        var form = await core.libs.form.handle($('#'+ret.form.id), ret, async data => {
            var ret = await core.libs.ws.sendAndWait({
                action: 'tag/edit',
                data: data
            });

            if (ret.errors) {
                form.showErrors(ret);
            }

            if (ret.success) {
                runAction('/tag/list');
            }
        });
    }
    
    async deleteAction(data) {
        if(!app.user.hasGroup('admin')) return ;
        
        var modal = showModalConfirm(core.lang.get('common.delete_confirm_message'), async () => {
            modal.setBodyWait();
            var ret = await core.libs.ws.sendAndWait(data);
            
            if (ret.error || ret.render_error) {
                showModalFail(ret.message, 5000);
            }
            runAction('/tag/list');
            modal.close();
        });
    }
    
}


app.tag = new Tag();