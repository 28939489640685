class ormAddress extends orm.base {
    
    fields() {
        var list = [];
        for(let field of ['venue_name', 'country', 'city', 'street', 'district', 'number', 'postcode']) {
            if(this[field]) {
                list.push({
                    label: core.lang.get('address.' + field),
                    value: this[field]
                });
            }
        }
        return list;
    }
    
    isEmpty() {
        return this.fields().length == 0;
    }
}

orm.address = ormAddress;
