class ormBooking extends orm.base {
    
    static Status = {
        Pending: 0,
        PaymentAuthAccepted: 1,
        PaymentAuthRejected: 2,
        Accepted: 10,
        Rejected: 20
    };
    
    constructor(data = {}) {
        super(data);
        
        this['is'+this.status_text] = true;
        this.statusText = core.lang.get('booking.status_' + this.status_text);
    }
    
    showAcceptButton() {
        return this.isPaymentAuthAccepted;
    }
    
    showRejectButton() {
        return this.isPending || this.isPaymentAuthAccepted;
    }
    
    hasContact() {
        return this.contact && this.contact.length > 0;
    }
    
    showClientData() {
        return this.isAccepted || app.user.groups.admin;
    }
}

orm.booking = ormBooking;
